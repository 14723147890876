var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ui-list',{attrs:{"headings":[
      _vm.$tc('models.building', 1),
      _vm.$tc('models.company'),
      _vm.$tc('models.role'),
      // ''
    ],"sort":[
      { prop: 'building', type: 'translation' },
      { prop: 'companyName', type: false },
    ],"items":_vm.buildingUsers},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":props.item.buildingName}}),_c('ui-list-data',{attrs:{"type":"tag","text":props.item.companyName}}),_c('ui-list-data',{attrs:{"type":"tag","text":_vm.$t('roles.BuildingAdmin')}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }