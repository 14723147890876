var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ui-list',{attrs:{"headings":[
        _vm.$t('labels.department.name'),
        _vm.$tc('models.building'),
        _vm.$tc('models.role'),
        // ''
      ],"sort":[
        { prop: 'building', type: 'translation' },
        { prop: 'department', prop2: 'name', type: 'level2' },
      ],"items":_vm.departments},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":props.item.departmentName}}),_c('ui-list-data',{attrs:{"type":"tag","text":props.item.buildingName}}),_c('ui-list-data',{attrs:{"type":"tag","text":_vm.$t('roles.DepartmentAdmin')}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }